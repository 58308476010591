import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [4,3];

export const dictionary = {
		"/(app)/(red)": [~13,[4,5]],
		"/(admin)/admin": [7,[2,3]],
		"/(admin)/admin/batches": [~8,[2,3]],
		"/(admin)/admin/branches": [~9,[2,3]],
		"/(admin)/admin/prizes": [~10,[2,3]],
		"/(admin)/admin/redemptions": [~11,[2,3]],
		"/(app)/(white)/bonus": [~15,[4,6]],
		"/(app)/(white)/code": [16,[4,6]],
		"/(app)/(white)/enter": [~17,[4,6]],
		"/(admin)/login": [~12,[2]],
		"/(app)/(white)/scratch": [~18,[4,6]],
		"/(app)/(white)/[batch=codepart]/[code=codepart]": [~14,[4,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';